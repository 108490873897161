.detail-listing-dekstop-deskripsi {
  display: flex;
  justify-content: space-between;
  width: 100%;
  max-width: 960px;
  border-bottom: 1px solid #e9e9e9;
  padding-bottom: 136px;
  margin-bottom: 136px;
  .header-deskripsi {
    width: 460px;
    font-weight: 600;
    font-size: 50px;
    line-height: 60px;
    color: #232323;
  }
  .deskripsi-content {
    width: 360px;
    .body-deskripsi {
      font-style: normal;
      font-weight: 400;
      font-size: 17px;
      line-height: 28px;
      color: #4f4f4f;
      white-space: pre-line;

      &.show-read-more {
        margin-bottom: 16px;
        display: -webkit-box;
        -webkit-line-clamp: 10;
        -webkit-box-orient: vertical;
        overflow: hidden;
      }
    }

    .footer-deskripsi {
      font-weight: 600;
      font-size: 17px;
      line-height: 22px;
      text-decoration-line: underline;
      color: #232323;
      cursor: pointer;
    }
  }
}

.dekstop-deskripsi-modal-content {
  width: 100vw;
  height: 100vh;
  background: #f5f5f5;
  display: flex;
  justify-content: center;
  .deskripsi-content-wrapper {
    width: 100%;
    max-width: 959px;
    margin-top: 40px;
    .modal-deskripsi-footer {
      display: flex;
      justify-content: flex-end;
      margin-bottom: 40px;
      .modal-deskripsi-exit-btn {
        background: #ffffff;
        border: 1px solid #232323;
        padding: 12px;
        display: flex;
        align-items: center;
        cursor: pointer;
        .modal-deskripsi-exit-icon {
          width: 24px;
          height: 24px;
          margin-right: 16px;
        }
        span {
          font-weight: 500;
          font-size: 15px;
          line-height: 20px;
          color: #232323;
        }
      }
    }

    .modal-deskripsi-wrapper {
      display: flex;
      justify-content: space-between;

      .modal-deskripsi-header {
        width: 260px;
        p {
          font-weight: 600;
          font-size: 50px;
          line-height: 60px;
          color: #a7a7a7;
        }
      }
      .modal-deskripsi-body {
        width: 559px;
        overflow-x: auto;
        height: calc(100vh - 50px - 40px);
        -ms-overflow-style: none;
        scrollbar-width: none;
        padding-bottom: 100px;
        &::-webkit-scrollbar {
          display: none;
        }
        .content-deskripsi-modal {
          font-weight: 400;
          font-size: 20px;
          line-height: 32px;
          color: #232323;
          white-space: pre-line;
        }
      }
    }
  }
}
